<template>
  <div class="flex items-center justify-between py-3">
    <div class="flex items-center space-x-4">
      <Avatar :player="answer.player" />
      <div class="font-medium dark:text-white">{{ answer.answer }}</div>
    </div>
    <div class="text-xl dark:text-white">
      {{ answer.score }}
    </div>
  </div>
</template>

<script>
import Avatar from "./Avatar"

export default {
  components: {
    Avatar
  },

  props: {
    answer: Object
  }
}
</script>

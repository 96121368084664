<template>
  <Screen :showReturnButton="true" @return="goBack">
    <template #title>New question</template>
    <form @submit.prevent="submit">
      <div>
        <PrettyLabel for="question" class="block">Express yourself</PrettyLabel>
        <PrettyTextarea
            id="question"
            ref="questionInput"
            rows="3"
            v-model="question"
            placeholder="For example: What makes @ angry?"
            class="mt-1 block w-full" />
        <div class="text-sm text-gray-300 mt-1">The <code>@</code> placeholder will be replaced to random player's name. You can use up to 3 placeholders in question.</div>
      </div>

      <div class="mt-4">
        <PrimaryButton type="submit" :disabled="submitting" :loading="submitting">
          Create
        </PrimaryButton>
      </div>
    </form>
  </Screen>
</template>
<script>
import { onMounted, ref } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import Screen from "@/components/Screen"
import PrimaryButton from "@/components/PrimaryButton"
import PrettyLabel from "@/components/Label"
import PrettyTextarea from "@/components/Textarea"

export default {
  components: {
    Screen,
    PrimaryButton,
    PrettyLabel,
    PrettyTextarea
  },

  setup () {
    const router = useRouter()
    const store = useStore()

    const goBack = async function () {
      await (window.history.length ? router.go(-1) : router.replace({ name: 'lobby' }))
    }

    const question = ref('')
    const questionInput = ref(null)

    onMounted(() => questionInput.value.focus())

    const submitting = ref(false)
    const submit = async function () {
      try {
        submitting.value = true
        await store.dispatch('questions/create', {
          question: question.value
        })
        await goBack()
      } catch (e) {
        questionInput.value.shake()
        questionInput.value.focus()
        throw e
      } finally {
        submitting.value = false
      }
    }

    return {
      goBack,
      questionInput,
      question,
      submitting,
      submit
    }
  }
}
</script>

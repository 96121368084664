<template>
  <Screen>
    <template #nav>
      <button @click="openProfile" class="h-10 w-10 overflow-hidden rounded-full focus:outline-none ring-2 ring-white focus:ring-offset-2 focus:ring-indigo-500">
        <img :src="user.avatarUrl" class="h-full w-full" :alt="user.username" />
      </button>
    </template>

    <div class="flex justify-center mt-8">
      <img src="@/assets/images/hobit.png" class="w-2/3 animate-bounce" alt="Hobot" />
    </div>

    <nav class="flex flex-col items-center space-y-3 mt-10">
      <PrimaryButton @click="newGame" :loading="startingMatch" class="w-2/3">New game</PrimaryButton>
      <SecondaryButton @click="joinGame" class="w-2/3">Join game</SecondaryButton>
      <RouterLink :to="{ name: 'createQuestion' }" class="text-sm dark:text-white">Add question</RouterLink>
    </nav>
  </Screen>
</template>

<script>
import { ref, computed } from "vue"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import Screen from "@/components/Screen"
import PrimaryButton from "@/components/PrimaryButton"
import SecondaryButton from "@/components/SecondaryButton"

export default {
  components: {
    Screen, PrimaryButton, SecondaryButton
  },

  setup () {
    const store = useStore()
    const router = useRouter()

    const startingMatch = ref(false)

    const openProfile = async function () {
      await router.push({ name: 'profile' })
    }

    const newGame = async function () {
      if (startingMatch.value) {
        return
      }

      startingMatch.value = true
      try {
        await store.dispatch('game/createGame')
        await router.replace({ name: 'game' })
      } finally {
        startingMatch.value = false
      }
    }

    const joinGame = async function () {
      await router.push({ name: 'joinGame' })
    }

    return {
      startingMatch,
      openProfile,
      newGame,
      joinGame,
      user: computed(() => store.state.user.user)
    }
  }
}
</script>

import { axios, createAvatarUrl } from '@/utils'
import { User } from '@/models'

export default {
  state: {
    token: localStorage.getItem('token'),
    user: new User()
  },
  getters: {
    hasToken: (state) => state.token !== null,
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    setUser (state, user) {
      state.user = user
    },
  },
  actions: {
    async fetchUser ({ state, commit }) {
      if (! state.token) {
        return
      }

      try {
        const { data } = await axios.get('user')

        commit('setUser', {
          id: data.id,
          username: data.username,
          createdAt: data.createdAt,
          avatarUrl: createAvatarUrl(data.avatarName),
          cringeAvatarUrl: createAvatarUrl(data.psychAvatarName),
        })
        commit('setToken', data.token)
      } catch (e) {
        commit('setToken', null)
      }
    },

    async register ({ dispatch, commit }, payload) {
      const res = await axios.post('user/register', payload)

      const { token } = res.data

      localStorage.setItem('token', token)
      commit('setToken', token)

      await dispatch('fetchUser')
    },

    async updateProfile ({ dispatch }, payload) {
      await axios.put('user', payload)
      await dispatch('fetchUser')
    },
  },
  namespaced: true,
}

<template>
  <Button class="shadow-sm border border-transparent text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><slot /></Button>
</template>

<script>
import Button from '@/components/Button'

export default {
  components: {
    Button
  }
}
</script>

import { axios, UserError } from '@/utils'
import {
  Game,
  Player,
  Question
} from '@/models'
import _ from 'lodash'

export default {
  state: {
    game: new Game,
    currentQuestion: null,
    players: [],
    config: {
      minPlayers: 3,
      minRounds: 1,
      maxRounds: 999,
      defaultRounds: 5
    },
    showGameResults: false
  },
  getters: {
    inGame: (state) => state.game.exists,
    isLastRound: (state) => (state.game.questionsCount == state.game.currentQuestionCount),
    status: (state, getters, rootState) => {
      const user = rootState.user.user

      if (! state.game.startedAt) {
        return 'game starting'
      }

      if (! state.currentQuestion.answers.some(a => a.userId === user.id)) {
        return 'round question'
      }

      if (! state.currentQuestion.allAnswered) {
        return 'round waiting answers'
      }

      const answer = state.currentQuestion.answers.find(a => a.userId === user.id)

      if (answer.voteAnswerId === null) {
        return 'round select favourite'
      }

      if (state.currentQuestion.answers.some((a) => a.voteAnswerId === null)) {
        return 'round waiting votes'
      }

      if (!state.showGameResults) {
        return 'round results'
      }

      return 'game results'
    },
    isHost: (state, getters, rootState) => state.game.ownerId !== null && state.game.ownerId === rootState.user.user.id,
    orderedPlayers: (state) => state.players.sort((a, b) => a.score > b.score ? -1 : 1)
  },
  mutations: {
    setGame (state, game) {
      state.game = game
    },
    setPlayers (state, players) {
      state.players = players
    },
    setCurrentQuestion (state, question) {
      state.currentQuestion = question
    },
    showGameResults(state) {
      state.showGameResults = true;
    },
    resetGameResults(state) {
      state.showGameResults = false;
    },
  },
  actions: {
    async createGame ({ dispatch }) {
      const { data } = await axios.post('game')

      localStorage.setItem('gameId', data.gameId)

      await dispatch('fetchCurrentGame')
    },

    async restartGame({ state, commit, dispatch }) {
      if (! state.game.id) return

      const { data } = await axios.post('game/' + state.game.id + '/new')

      commit('resetGameResults')
      localStorage.setItem('gameId', data.gameId)

      await dispatch('fetchCurrentGame')
    },

    async joinGame ({ dispatch }, { code }) {
      try {
        const { data } = await axios.post('game/join', {
          code: code
        })

        localStorage.setItem('gameId', data.gameId)

        await dispatch('fetchCurrentGame')
      } catch (e) {
        if (e.response) {
          // Handle Game not found
          if (e.response.status === 404) {
            throw new UserError('Game not found :(')
          }
        }
        throw e
      }
    },

    async startGame ({ state, dispatch }, payload) {
      if (! state.game.id) return

      await axios.post('game/' + state.game.id + '/start', payload)

      await dispatch('fetchCurrentGame')
    },

    async fetchCurrentGame ({ commit, dispatch }) {
      if (! ('gameId' in localStorage)) {
        return
      }

      try {
        const { data } = await axios.get('game/' + localStorage.getItem('gameId'))

        commit('setGame', new Game(_.omit(data.game, 'currentQuestion', 'users')))
        commit('setCurrentQuestion', new Question(data.currentQuestion))
        commit('setPlayers', data.users.map(user => {
          return new Player(user)
        }))

        if (data.nextGame) {
          await dispatch('joinGame', data.nextGame.code)
        }
      } catch (e) {
        console.error(e)
      }
    },

    async submitAnswer ({ state, dispatch }, payload) {
      if (! state.game.id) return

      try {
        await axios.post('game/' +  state.game.id + '/answer', payload)
      } catch (e) {
        if (e.response) {
          // Handle Duplicate answer
          if (e.response.status === 409) {
            throw new UserError(e.response.data.error)
          }
        }
        throw e
      }

      await dispatch('fetchCurrentGame')
    },

    async voteForAnswer ({ dispatch, state }, { answerId }) {
      if (! state.game.id) return

      try {
        await axios.post('game/' +  state.game.id + '/vote/' + answerId)
      } catch (e) {
        if (e.response) {
          // Handle Cannot vote for your own answer
          if (e.response.status === 403) {
            throw new UserError(e.response.data.error)
          }
        }
        throw e
      }

      await dispatch('fetchCurrentGame')
    },

    async readyForNextRound ({ dispatch, state }) {
      if (! state.game.id) return

      await axios.post('game/' +  state.game.id + '/ready/')

      await dispatch('fetchCurrentGame')
    },

    async kickPlayer ({ dispatch, state }, payload) {
      if (! state.game.id) return

      await axios.post('game/' +  state.game.id + '/kick/', payload)

      await dispatch('fetchCurrentGame')
    },

    async leaveGame ({ commit, state }) {
      if (! state.game.id) return

      try {
        await axios.post('game/' + state.game.id + '/leave')
      } catch (e) {
        console.error(e)
      }

      commit('setGame', new Game)
      commit('setPlayers', [])
      commit('setCurrentQuestion', new Question)
      commit('resetGameResults')
      localStorage.removeItem('gameId')
    }
  },
  namespaced: true,
}

<template>
  <div class="h-screen flex flex-col items-center justify-center">
    <div class="flex flex-col items-center text-indigo-700 dark:text-white">
      <RefreshIcon class="w-20 h-20 animate-spin" />
      <div class="mt-2">Fetching good vibes</div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { RefreshIcon } from "@heroicons/vue/solid"

export default {
  components: {
    RefreshIcon
  },

  setup () {
    const store = useStore()
    const router = useRouter()

    onMounted(async () => {
      if (! store.state.bootstrapped) {
        await store.dispatch('bootstrap')
      }
      await router.replace({ name: 'lobby' })
    })

    return {}
  }
}
</script>

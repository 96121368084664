<template>
  <div>
    <form @submit.prevent="submitAnswer">
      <div class="mt-4">
        <PrettyLabel for="code" class="block">Your answer</PrettyLabel>
        <PrettyInput
            type="text"
            v-model="answer"
            ref="answerInput"
            class="mt-1 block w-full"
        />
      </div>

      <div class="mt-4">
        <PrimaryButton type="submit" :disabled="submitting" :loading="submitting">
          Submit
        </PrimaryButton>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue"
import { useStore } from "vuex"
import PrimaryButton from "@/components/PrimaryButton"
import PrettyLabel from "@/components/Label"
import PrettyInput from "@/components/Input"

export default {
  components: {
    PrimaryButton,
    PrettyLabel,
    PrettyInput,
  },

  setup () {
    const store = useStore()

    const isLastRound = computed(() => store.getters['game/isLastRound'])

    const answer = ref('')
    const answerInput = ref(null)

    onMounted(() => {
      if (isLastRound.value) {
        const audio = new Audio('/audio/' + 'finalRound.mp4')
        audio.play()
      }

      answerInput.value.focus()
    })

    const submitting = ref(false)
    const submitAnswer = async function () {
      if (!answer.value) {
        answerInput.value.shake()
        answerInput.value.focus()
        return
      }

      submitting.value = true
      try {
        await store.dispatch('game/submitAnswer', {
          answer: answer.value
        })
      } finally {
        submitting.value = false
      }
    }

    return {
      answer,
      answerInput,
      submitting,
      submitAnswer
    }
  }
}
</script>

import store from '@/store'
import Lobby from "@/views/Lobby"
import Register from "@/views/Register"
import Profile from "@/views/Profile"
import JoinGame from "@/views/JoinGame"
import Game from "@/views/Game"
import Loading from "@/views/Loading"
import CreateQuestion from "@/views/CreateQuestion"

export default [
  {
    path: '/lobby',
    name: 'lobby',
    component: Lobby,
    beforeEnter: () => {
      if (store.getters['game/inGame']) return { name: 'game' }
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: () => {
      if (store.getters['user/hasToken']) return { name: 'lobby' }
    },
  },
  {
    path: '/',
    name: 'loading',
    component: Loading
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/questions/create',
    name: 'createQuestion',
    component: CreateQuestion
  },
  {
    path: '/game/join',
    name: 'joinGame',
    component: JoinGame,
    beforeEnter: () => {
      if (store.getters['game/inGame']) return { name: 'game' }
    },
  },
  {
    path: '/game',
    name: 'game',
    component: Game,
    beforeEnter: () => {
      if (! store.getters['game/inGame']) return { name: 'lobby' }
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

import { axios, UserError } from '@/utils'

export default {
  state: {
    ratings: JSON.parse(localStorage.getItem('ratings')) || []
  },
  getters: {
    getRating: (state) => (questionId) => {
      const r = state.ratings.find(r => r.questionId === questionId)
      return r ? r.rating : undefined
    }
  },
  mutations: {
    addRating (state, data) {
      state.ratings.push(data)
      localStorage.setItem('ratings', JSON.stringify(state.ratings))
    }
  },
  actions: {
    async create (_, payload) {
      try {
        await axios.post('questions', payload)
      } catch (e) {
        if (e.response) {
          if (e.response.status === 400) {
            throw new UserError(e.response.data.error)
          }
        }
      }
    },

    async rate ({ commit }, payload) {
      try {
        await axios.post('questions/' + payload.questionId + '/rate', {
          rating: payload.rating
        })

        commit('addRating', payload)
      } catch (e) {
        if (e.response) {
          if ([400, 429].includes(e.response.status)) {
            throw new UserError(e.response.data.error)
          }
        }
      }
    }
  },
  namespaced: true,
}

<template>
  <button :type="type"
          class="inline-flex justify-center py-2 px-4 text-sm font-medium rounded-md disabled:opacity-50">
    <slot />
    <RefreshIcon v-if="loading" class="w-5 h-5 ml-2 animate-spin" />
  </button>
</template>

<script>
import { RefreshIcon } from '@heroicons/vue/solid'

export default {
  components: {
    RefreshIcon,
  },

  props: {
    type: {
      type: String,
      default: 'button'
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <Screen>
    <template #title>{{ title }}</template>
    <template #subtitle v-if="! ['game starting', 'game results'].includes(status)">
      Round {{ game.currentQuestionCount }} of {{ game.questionsCount }}
    </template>

    <template #nav>
      <Menu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton class="h-10 w-10 overflow-hidden rounded-full focus:outline-none ring-2 ring-white focus:ring-offset-2 focus:ring-indigo-500">
            <img :src="user.avatarUrl" class="h-full w-full" :alt="user.username" />
          </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1 divide-y divide-gray-100">
              <MenuItem>
                <RouterLink :to="{ name: 'profile' }" class="text-gray-700 block w-full px-4 py-2 text-sm">Profile</RouterLink>
              </MenuItem>
              <MenuItem>
                <RouterLink :to="{ name: 'createQuestion' }" class="text-gray-700 block w-full px-4 py-2 text-sm">Add Question</RouterLink>
              </MenuItem>
              <MenuItem>
                <button @click="leaveGame" class="text-gray-700 block w-full px-4 py-2 text-sm text-left">Leave Game</button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </template>

    <div class="mt-4">
      <Question v-if="['round question', 'round waiting answers', 'round select favourite', 'round waiting votes'].includes(status)" />
      <GameStart v-if="status === 'game starting'" />
      <RoundQuestion v-else-if="status === 'round question'" />
      <RoundWaitingAnswers v-else-if="status === 'round waiting answers'" />
      <RoundSelectFavourite v-else-if="status === 'round select favourite'" />
      <RoundWaitingVotes v-else-if="status === 'round waiting votes'" />
      <RoundResults v-else-if="status === 'round results'" />
      <GameResults v-else-if="status === 'game results'" />
    </div>
  </Screen>
</template>

<script>
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { computed, onMounted } from "vue"
import Screen from "@/components/Screen"
import Question from "./Game/Question"
import GameStart from "./Game/GameStart"
import RoundQuestion from "./Game/RoundQuestion"
import RoundWaitingAnswers from "./Game/RoundWaitingAnswers"
import RoundSelectFavourite from "./Game/RoundSelectFavourite"
import RoundWaitingVotes from "./Game/RoundWaitingVotes"
import RoundResults from "./Game/RoundResults"
import GameResults from "./Game/GameResults"
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

export default {
  components: {
    Screen,
    Question,
    GameStart,
    RoundQuestion,
    RoundWaitingAnswers,
    RoundSelectFavourite,
    RoundWaitingVotes,
    RoundResults,
    GameResults,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
  },

  setup () {
    const store = useStore()
    const router = useRouter()

    const status = computed(() => store.getters['game/status'])

    // The heart of game loop
    onMounted(() => {
      setInterval(async () => {
        await store.dispatch('game/fetchCurrentGame')
      }, 3000)
    })

    const leaveGame = async function () {
      await store.dispatch('game/leaveGame')
      await router.replace({ name: 'lobby' })
    }

    return {
      status,
      leaveGame,
      user: computed(() => store.state.user.user),
      game: computed(() => store.state.game.game),
      title: computed(() => {
        switch (status.value) {
          case 'game starting':
            return 'Starting New Game';
          case 'round question':
            return 'Answer the question';
          case 'round waiting answers':
            return 'Waiting other players';
          case 'round select favourite':
            return 'Select top answer';
          case 'round results':
            return 'Round Results';
          case 'round waiting votes':
            return 'Waiting other players';
          case 'game results':
            return 'We have a winner!';
        }
        return 'Do you wanna play a game?'
      }),
    }
  }
}
</script>

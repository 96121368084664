<template>
  <div class="mt-4">
    <h2 class="text-2xl dark:text-white">Voters</h2>
    <PlayersList :players="players" :readinessStrategy="readinessStrategy" />

    <h2 class="text-2xl dark:text-white">Answers</h2>

    <div class="my-4 divide-y dark:divide-gray-500">
      <div v-for="answer in roundAnswers"
           :key="`answer-${answer.id}`">
        <div class="font-medium dark:text-white py-3">{{ answer.answer }}</div>
      </div>
    </div>

    <div class="text-gray-500 my-2">Collecting answers...</div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import PlayersList from "@/components/PlayersList"

export default {
  components: {
    PlayersList
  },

  setup () {
    const store = useStore()

    const question = computed(() => store.state.game.currentQuestion)
    const players = computed(() => store.getters['game/orderedPlayers'])

    return {
      players,
      readinessStrategy: (player) => {
        const answer = question.value.answers.find(a => a.userId === player.id)
        return answer.voteAnswerId !== null
      },
      roundAnswers: computed(() => {
        return question.value.answers.map(answer => {
          return {
            answer: answer.answer
          }
        })
      })
    }
  }
}
</script>

import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import routes from "./routes"
import { trace } from '@/utils'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const storeDeferredGameCode = async (to, from) => {
  trace('router:storeDeferredGameCode', to, from)

  if (Object.prototype.hasOwnProperty.call(from.query, 'join')) {
    localStorage.setItem('deferredGameCode', from.query.join)
  }
}

const redirectToLoadingIfNotBootstrapped = async (to, from) => {
  trace('router:redirectToLoadingIfNotBootstrapped', to, from)

  if (! store.state.bootstrapped) {
    if (to.name !== 'loading') return { name: 'loading' }
  }
}

const redirectGuestToRegistration = async (to, from) => {
  trace('router:redirectGuestToRegistration', to, from)

  if (store.state.bootstrapped) {
    if (! store.getters['user/hasToken'] && to.name !== 'register') {
      return { name: 'register' }
    }
  }
}

const joinGameByInvitationLink = async (to, from) => {
  trace('router:joinGameByInvitationLink', to, from)

  if (store.state.bootstrapped) {
    if (store.getters['user/hasToken'] && ! store.getters['game/inGame']) {
      const deferredGameCode = localStorage.getItem('deferredGameCode')
      if (deferredGameCode) {
        try {
          trace('joining the game by query string code', deferredGameCode)
          await store.dispatch('game/joinGame', {
            code: deferredGameCode
          })
          if (to.name !== 'game') return { name: 'game' }
        } catch (e) {
          console.error(e) // suppress
        } finally {
          localStorage.removeItem('deferredGameCode')
        }
      }
    }
  }
}

router.beforeEach(storeDeferredGameCode)
router.beforeEach(redirectToLoadingIfNotBootstrapped)
router.beforeEach(redirectGuestToRegistration)
router.beforeEach(joinGameByInvitationLink)

export default router

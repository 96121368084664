<template>
  <div>
    <PlayersList :players="players" :readinessStrategy="readinessStrategy" />

    <div class="text-gray-500 my-2">Waiting for everyone answers...</div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import PlayersList from "@/components/PlayersList"

export default {
  components: {
    PlayersList
  },

  setup () {
    const store = useStore()

    const question = computed(() => store.state.game.currentQuestion)
    const players = computed(() => store.getters['game/orderedPlayers'])

    return {
      players,
      readinessStrategy: (player) => question.value.answers.some(a => a.userId === player.id)
    }
  }
}
</script>

const enableTrace = false

const trace = function (action, to, from) {
  if (enableTrace) {
    console.info(action, to, from)
  }
}

export {
  trace
}

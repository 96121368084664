import { createAvatarUrl } from '@/utils'

export default class Player {
  constructor (source = {}) {
    Object.assign(this, source)
  }

  get avatarUrl () {
    return createAvatarUrl(this.avatarName)
  }

  get cringeAvatarUrl () {
    return createAvatarUrl(this.psychAvatarName)
  }

  get isOffline () {
    return ['kicked', 'left', 'disconnected'].includes(this.status)
  }
}

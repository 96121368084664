<template>
  <div class="w-full max-w-md min-h-screen mx-auto relative">
    <!-- Network Error -->
    <div v-if="hasNetworkError" class="h-screen flex flex-col items-center justify-center">
      <div class="flex flex-col items-center text-red-500 animate-pulse">
        <StatusOfflineIcon class="w-20 h-20" />
        <div class="mt-2">Unable to reach game server</div>
      </div>
    </div>
    <!-- Router -->
    <RouterView v-else class="min-h-screen p-6" @showBanner="showBanner" />
    <!-- Banner -->
    <teleport to="#app">
      <Banner v-if="bannerMessage" @click="bannerMessage = null" class="bg-indigo-600 fixed inset-x-2 bottom-0 rounded-lg mb-6 max-w-md mx-auto">{{ bannerMessage }}</Banner>
    </teleport>
  </div>
</template>

<script>
import { onErrorCaptured, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { StatusOfflineIcon } from '@heroicons/vue/solid'
import { onNetworkConnectionEstablished, onNetworkErrorOccurred, UserError } from "./utils"
import Banner from '@/components/Banner'
import { Plugins } from '@capacitor/core'

export default {
  components: {
    Banner, StatusOfflineIcon
  },

  setup () {
    const { SplashScreen } = Plugins

    onMounted(() => SplashScreen.hide())

    const store = useStore()
    const hasNetworkError = ref(false)
    const bannerMessage = ref(null)

    const showBanner = function (message) {
      bannerMessage.value = message

      setTimeout(() => bannerMessage.value = null, 1000)
    }

    onErrorCaptured(e => {
      if (e instanceof UserError) {
        showBanner(e.message)
      }
    })

    // Handle network errors
    onNetworkConnectionEstablished(() => hasNetworkError.value = false)
    onNetworkErrorOccurred(() => hasNetworkError.value = true)

    // Switch theme
    const applyTheme = function (theme) {
      theme === 'dark' ? document.documentElement.classList.add('dark') : document.documentElement.classList.remove('dark');
    }

    watch(() => store.state.preferences.theme, theme => applyTheme(theme))
    applyTheme(store.state.preferences.theme)

    return {
      hasNetworkError,
      bannerMessage,
      showBanner
    }
  }
}
</script>

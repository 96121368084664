<template>
  <div>
    <PlayersList :players="players" :show-score="true" />

    <div class="flex items-center mt-4 space-x-2">
      <PrimaryButton
        @click="restartGame"
        :disabled="restarting"
        :loading="restarting"
      >
        Restart
      </PrimaryButton>

      <SecondaryButton
        @click="leaveGame"
        :disabled="restarting"
      >
        Leave
      </SecondaryButton>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import PrimaryButton from "@/components/PrimaryButton";
import SecondaryButton from "@/components/SecondaryButton";
import PlayersList from "@/components/PlayersList";

export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    PlayersList,
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const players = computed(() => store.state.game.players)
    const isHost = computed(() => store.getters['game/isHost'])

    const restarting = ref(false)
    const restartGame = async function () {
      if (!isHost.value) {
        window.open('href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"', '_blank')
        return
      }

      restarting.value = true
      try {
        await store.dispatch('game/restartGame')
      } finally {
        restarting.value = false
      }
    }

    const leaveGame = async function () {
      await store.dispatch('game/leaveGame')
      await router.replace({ name: 'lobby' })
    }

    return {
      players,
      isHost,
      restarting,
      restartGame,
      leaveGame,
    };
  },
};
</script>

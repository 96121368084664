<template>
  <div v-if="trolling && trolledBy" class="absolute inset-0">
    <div class="flex h-full flex-col items-center justify-center overflow-hidden">
      <img :src="trolledBy.cringeAvatarUrl" class="w-2/3 rounded-full focus:outline-none ring-2" :class="{['avatar-trolling' + (Math.round(Math.random()) + 1)]: true}" />
      <div class="text-3xl text-white mt-8">{{ trolledBy.username }}</div>
      <SecondaryButton @click="stopTrollingMe" class="mt-8">I'm fine</SecondaryButton>
    </div>
  </div>
  <div v-else>
    <div class="text-lg mb-2 dark:text-white">
      <template v-if="! votedForMyAnswer.length">No one</template>
      <template v-else-if="votedForMyAnswer.length === 1">{{ votedForMyAnswer.map(u => u.username).join(', ') }}</template>
      <template v-else>{{ votedForMyAnswer.map(u => u.username).slice(0, -1).join(', ') }} and {{ votedForMyAnswer.map(u => u.username).pop() }}</template>
       picked your answer.
    </div>

    <h2 class="text-2xl dark:text-white mt-4">Answers</h2>
    <AnswersList :answers="answers"/>

    <h2 class="text-2xl dark:text-white mt-4">Leaderboard</h2>
    <PlayersList :players="players" :showScore="true" :readinessStrategy="readinessStrategy" />
    <!--  %username% is unstoppable!  -->

    <div class="flex justify-center items-center mt-4">
      <div v-if="!iReadyForNextRound && !isLastRound">
        <PrimaryButton  @click="readyForNextRound" :disabled="preparing" :loading="preparing">Ready</PrimaryButton>
      </div>
      <div v-if="isLastRound">
        <PrimaryButton  @click="showGameResults">Show Game Results</PrimaryButton>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from "vuex"
import PrimaryButton from "@/components/PrimaryButton"
import SecondaryButton from "@/components/SecondaryButton"
import AnswersList from "@/components/AnswersList"
import PlayersList from "@/components/PlayersList"

export default {
  components: {
    PrimaryButton, SecondaryButton, AnswersList, PlayersList
  },

  // props: {
  //   game: Object,
  //   question: Question,
  //   user: Object,
  //   loading: Boolean,
  //   players: Array,
  //   isLastRound: Boolean
  // },

  setup () {
    const store = useStore()

    const user = computed(() => store.state.user.user)
    const game = computed(() => store.state.game.game)
    const question = computed(() => store.state.game.currentQuestion)
    const players = computed(() => store.getters['game/orderedPlayers'])

    const isLastRound = computed(() => store.getters['game/isLastRound'])

    const preparing = ref(false)
    const readyForNextRound = async function () {
      preparing.value = true
      try {
        await store.dispatch('game/readyForNextRound')
      } finally {
        preparing.value = false
      }
    }

    const iReadyForNextRound = computed(() => question.value.answers.some(a => a.userId === user.value.id && !!a.readyForNextRound))

    const trolling = ref(true)
    document.body.classList.add('bg-trolling')

    // if (process.env.NODE_ENV === 'production') {
    //   const sounds = [
    //     'Airhorn',
    //     'LetsGo',
    //     'Noice',
    //     'ToBeContinued',
    //     'WetFart',
    //     'Wow',
    //     'Yeet'
    //   ]
    //   const audio = new Audio('/audio/' + sounds[question.valueId % sounds.length] + '.mp3')
    //   audio.play()
    // }

    const stopTrollingMe = function () {
      trolling.value = false
      document.body.classList.remove('bg-trolling')
    }

    const showGameResults = function() {
      store.commit('game/showGameResults');
    }

    return {
      user,
      game,
      question,
      players,
      isLastRound,
      trolling,
      preparing,
      readyForNextRound,
      iReadyForNextRound,
      showGameResults,
      stopTrollingMe,
      trolledBy: computed(() => {
        const answer = question.value.answers.find(a => a.userId === user.value.id)
        const voted = question.value.answers.find(a => answer && answer.voteAnswerId === a.id)
        return players.value.find(u => voted && voted.userId === u.id)
      }),
      answers: computed(() => {
        return question.value.answers.map(answer => {
          const playerAnswer = question.value.answers.find(a => a.userId === answer.userId)
          return {
            answer: answer.answer,
            player: players.value.find(u => u.id === answer.userId),
            score: question.value.answers.filter(a => a.voteAnswerId === playerAnswer.id).length
          }
        }).sort((a, b) => a.score > b.score ? -1 : 1)
      }),
      readinessStrategy: (player) => question.value.answers.some(a => a.userId === player.id && !!a.readyForNextRound),
      votedForMyAnswer: computed(() => {
        const answer = question.value.answers.find(a => a.userId === user.value.id)
        return question.value.answers.filter(a => a.voteAnswerId === answer.id).map(a => {
          return players.value.find(u => u.id === a.userId)
        })
      })
    }
  }
}
</script>

import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3001'
})

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = token
  }
  return config
})

const networkConnectionEstablishedHooks = []
const onNetworkConnectionEstablished = (hook) => networkConnectionEstablishedHooks.push(hook)

const networkErrorOccurredHooks = []
const onNetworkErrorOccurred = (hook) => networkErrorOccurredHooks.push(hook)

// Handle network errors
instance.interceptors.response.use(response => {
  networkConnectionEstablishedHooks.forEach(hook => {
    hook.call(null, response)
  })
  return response
}, e => {
  if (e.request && ! e.response) {
    networkErrorOccurredHooks.forEach(hook => {
      hook.call(null, e)
    })
  }
  return Promise.reject(e);
})

export {
  instance as axios,
  onNetworkConnectionEstablished,
  onNetworkErrorOccurred
}

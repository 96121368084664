<template>
  <div>
    <div class="text-gray-700 dark:text-gray-300 my-4 text-xl">
      {{ question.formattedQuestion }}
    </div>

    <div class="flex justify-between items-center">
      <div class="flex -space-x-1">
        <Avatar v-for="(player) in playersMentionedInQuestion" :key="player.id" :player="player"></Avatar>
      </div>
      <div class="flex items-center space-x-2">
        <button v-if="rating === undefined || rating === -1"
                type="button"
                :disabled="rating !== undefined"
                @click="rateQuestion(-1)"
                class="cursor-pointer">
          <ThumbDownIcon class="w-6 h-6 text-red-500" />
        </button>
        <button v-if="rating === undefined || rating === 1"
                type="button"
                :disabled="rating !== undefined"
                @click="rateQuestion(1)"
                class="cursor-pointer">
          <ThumbUpIcon class="w-6 h-6 text-green-500" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import Avatar from "@/components/Avatar"
import { ThumbDownIcon, ThumbUpIcon } from "@heroicons/vue/solid"

export default {
  components: {
    Avatar,
    ThumbUpIcon,
    ThumbDownIcon
  },

  setup () {
    const store = useStore()

    const game = computed(() => store.state.game.game)
    const question = computed(() => store.state.game.currentQuestion)
    const players = computed(() => store.state.game.players)

    const rating = computed(() => store.getters['questions/getRating'](game.value.currentQuestionId))

    const rateQuestion = async function (rating) {
      await store.dispatch('questions/rate', {
        questionId: game.value.currentQuestionId,
        rating: rating
      })
    }

    const playersMentionedInQuestion = computed(() => {
      return players.value.filter(player => {
        return question.value.meta.includes(player.id)
      })
    })

    return {
      question,
      rating,
      rateQuestion,
      playersMentionedInQuestion
    }
  }
}
</script>

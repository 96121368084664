<template>
  <Screen :showReturnButton="true" @return="goBack">
    <template #title>Join game</template>
    <form @submit.prevent="submit">
      <div>
        <PrettyLabel for="code" class="block">Enter code</PrettyLabel>
        <PrettyInput type="text"
                     id="code"
                     v-model="code"
                     ref="codeInput"
                     maxlength="4"
                     inputmode="numeric"
                     class="mt-1 block w-48" />
      </div>

      <div class="mt-4">
        <PrimaryButton type="submit" :disabled="submitting" :loading="submitting">
          Find
        </PrimaryButton>
      </div>
    </form>
  </Screen>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Screen from "@/components/Screen"
import PrimaryButton from "@/components/PrimaryButton"
import PrettyLabel from "@/components/Label"
import PrettyInput from "@/components/Input"

export default {
  components: {
    Screen,
    PrimaryButton,
    PrettyLabel,
    PrettyInput
  },

  setup () {
    const store = useStore()
    const router = useRouter()

    const goBack = async function () {
      if (window.history.length) {
        await router.go(-1)
      }
      await router.replace({ name: 'lobby' })
    }

    const code = ref('')
    const codeInput = ref(null)
    const submitting = ref(false)

    onMounted(() => codeInput.value.focus())

    const submit = async function () {
      if (!code.value) {
        codeInput.value.shake()
        codeInput.value.focus()
        return
      }

      submitting.value = true
      try {
        await store.dispatch('game/joinGame', {
          code: code.value
        })
        await router.replace({ name: 'game' })
      } catch (e) {
        codeInput.value.shake()
        codeInput.value.focus()
        throw e
      } finally {
        submitting.value = false
      }
    }

    return {
      goBack,
      code,
      codeInput,
      submitting,
      submit
    }
  }
}
</script>

<template>
  <div class="bg-indigo-600">
    <div class="max-w-7xl mx-auto py-3 px-3">
      <div class="flex items-center justify-between flex-wrap">
        <div class="w-0 flex-1 flex items-center">
          <span class="flex p-2 rounded-lg bg-indigo-800">
            <ThumbDownIcon class="h-6 w-6 text-white animate-bounce mt-1 -mb-1" aria-hidden="true" />
          </span>
          <p class="ml-3 text-white truncate">
            <slot />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ThumbDownIcon } from '@heroicons/vue/outline'

export default {
  components: {
    ThumbDownIcon
  }
}
</script>

<template>
  <div>
    <InvitePlayers :code="game.code" />

    <PlayersList :players="players" :readinessStrategy="readinessStrategy" />

    <div class="text-gray-500 dark:text-gray-300 text-sm animate-pulse">Waiting for other gamers...</div>

    <form v-if="isHost" @submit.prevent="startGame">
      <div class="mt-4">
        <PrettyLabel for="code" class="block">Rounds</PrettyLabel>
        <PrettyInput type="number"
                     v-model="roundsCount"
                     :min="config.minRounds"
                     :max="config.maxRounds"
                     inputmode="numeric"
                     pattern="[0-9]*"
                     class="mt-1 block w-24" />
      </div>

      <div class="mt-4">
        <PrimaryButton type="submit" :disabled="starting || ! canStartGame" :loading="starting">
          Start game
        </PrimaryButton>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, ref } from "vue"
import { useStore } from "vuex"
import PrimaryButton from "@/components/PrimaryButton"
import PrettyLabel from "@/components/Label"
import PrettyInput from "@/components/Input"
import PlayersList from "@/components/PlayersList"
import InvitePlayers from '@/components/InvitePlayers'

export default {
  components: {
    PrimaryButton, PrettyLabel, PrettyInput, PlayersList, InvitePlayers
  },

  setup () {
    const store = useStore()

    const config = computed(() => store.state.game.config)
    const players = computed(() => store.state.game.players)
    const roundsCount = ref(config.value.defaultRounds)
    const isHost = computed(() => store.getters['game/isHost'])
    const canStartGame = computed(() => isHost.value && players.value.length >= config.value.minPlayers)
    const starting = ref(false)
    const startGame = async function () {
      if (! canStartGame.value) return

      starting.value = true
      try {
        await store.dispatch('game/startGame', {
          questionsCount: roundsCount.value
        })
      } finally {
        starting.value = false
      }
    }

    return {
      config,
      players,
      roundsCount,
      isHost,
      canStartGame,
      starting,
      startGame,
      game: computed(() => store.state.game.game),
      readinessStrategy: (player) => player.isReady
    }
  }
}
</script>

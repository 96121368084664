<template>
  <div>
    <div class="dark:text-white mb-2">Secret code: <strong>{{ code }}</strong></div>

    <SecondaryButton v-if="useNavigator" @click.prevent="shareWithNavigator">
      <ShareIcon class="w-5 h-5 mr-2" />
      Invite friends
    </SecondaryButton>

    <SecondaryButton v-else @click.prevent="copyJoinLink">
      <template v-if="justCopied">
        <CheckIcon class="w-5 h-5 text-green-500 mr-2" />
        <span class="text-green-500">Copied</span>
      </template>
      <template v-else>
        <ClipboardCopyIcon class="w-5 h-5 mr-2" />
        <span>Copy link</span>
      </template>
    </SecondaryButton>
  </div>
</template>

<script>
import { ref } from "vue"
import { copyTextToClipboard, createJoinGameUrl } from '@/utils'
import { ShareIcon, ClipboardCopyIcon, CheckIcon } from '@heroicons/vue/solid'
import SecondaryButton from "@/components/SecondaryButton"

export default {
  props: {
    code: String
  },

  components: {
    ShareIcon, ClipboardCopyIcon, CheckIcon, SecondaryButton
  },

  setup (props) {
    const joinLink = createJoinGameUrl(props.code)

    const useNavigator = !!navigator.share // works only on mobile devices hosted with secure connection
    const shareWithNavigator = async function () {
      if (useNavigator) {
        await navigator.share({
          title: "Let's go dude!",
          text: "Join game with code: " + props.code,
          url: joinLink
        })
      }
    }

    const justCopied = ref(false)
    const copyJoinLink = function () {
      justCopied.value = true

      copyTextToClipboard(joinLink)

      setTimeout(() => justCopied.value = false, 1000)
    }

    return {
      useNavigator,
      shareWithNavigator,
      justCopied,
      copyJoinLink,
    }
  }
}
</script>

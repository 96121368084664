<template>
  <div
      class="h-12 w-12 overflow-hidden rounded-full focus:outline-none ring-2 ring-white focus:ring-offset-2 focus:ring-indigo-500">
    <img :src="player.avatarUrl" class="h-full w-full" :alt="player.username" />
  </div>
</template>

<script>
export default {
  props: {
    player: Object
  }
}
</script>

<template>
  <Button class="shadow-sm border border-gray-300 text-gray-700 bg-white focus:outline-none focus:border-blue-300 focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 active:text-gray-800 active:bg-gray-50"><slot /></Button>
</template>

<script>
import Button from '@/components/Button'

export default {
  components: {
    Button
  }
}
</script>

<template>
  <TransitionGroup name="list" tag="div" class="my-4 divide-y dark:divide-gray-500">
    <AnswerItem v-for="answer in answers"
                :key="`answer-${answer.id}`"
                :answer="answer" />
  </TransitionGroup>
</template>

<script>
import AnswerItem from '@/components/AnswerItem'

export default {
  components: {
    AnswerItem
  },

  props: {
    answers: Array
  }
}
</script>

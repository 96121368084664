<template>
  <Screen :showReturnButton="true" @return="goBack">
    <template #title>Profile</template>
    <form @submit.prevent="goBack">
      <div>
        <PrettyLabel for="username" class="block">Username</PrettyLabel>
        <div class="relative">
          <PrettyInput type="text"
                       id="username"
                       ref="usernameInput"
                       v-model="username"
                       @input="usernameChanged"
                       class="mt-2 block w-full" />
          <CheckCircleIcon v-show="usernameSaved" class="w-6 h-6 text-green-500 absolute right-1 top-2" />
        </div>
      </div>

      <div class="mt-6">
        <PrettyLabel class="block">
          Avatar
        </PrettyLabel>
        <div class="mt-2 flex items-center">
          <span class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100 focus:outline-none ring-2 ring-white focus:ring-offset-2 focus:ring-indigo-500">
            <img :src="user.avatarUrl" class="h-full w-full" alt="Avatar" />
          </span>
          <div class="hidden">
            <input type="file" ref="avatarFileInput" accept="image/*" @change="avatarPicked('avatar', $event)">
          </div>
          <SecondaryButton type="button" class="ml-5" @click="avatarFileInput.click()">
            Change
          </SecondaryButton>
        </div>
      </div>

      <div class="mt-6">
        <PrettyLabel class="block">
          Cringe avatar
        </PrettyLabel>
        <div class="mt-2 flex items-center">
          <span class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100 focus:outline-none ring-2 ring-white focus:ring-offset-2 focus:ring-indigo-500">
            <img :src="user.cringeAvatarUrl" class="h-full w-full" alt="Cringe avatar" />
          </span>
          <div class="hidden">
            <input type="file" ref="cringeAvatarFileInput" accept="image/*" @change="avatarPicked('psychAvatar', $event)">
          </div>
          <SecondaryButton type="button" class="ml-5" @click="cringeAvatarFileInput.click()">
            Change
          </SecondaryButton>
        </div>
      </div>

      <teleport to="#app">
        <div v-show="avatarSource" class="fixed bg-white inset-0 z-10"></div>
      </teleport>
      <div  v-show="avatarSource" class="absolute inset-0 z-20">
        <VueCropper ref="avatarCropper"
                    :src="avatarSource"
                    :aspectRatio="1"
                    :zoomable="false" />
        <div class="flex justify-center space-x-2 mt-4">
          <SecondaryButton @click="avatarSource = null" type="button" :disabled="avatarUploading">
            Cancel
          </SecondaryButton>
          <PrimaryButton @click="avatarCrop" type="button" :disabled="avatarUploading" :loading="avatarUploading">
            Submit
          </PrimaryButton>
        </div>
      </div>

      <div class="mt-8">
        <SwitchGroup>
          <div class="flex items-center space-x-2">
            <SwitchLabel passive>
              <SunIcon class="w-6 h-6 text-yellow-500" />
            </SwitchLabel>
            <Switch
                v-model="useDarkTheme"
                :class="useDarkTheme ? 'bg-green-600' : 'bg-green-500'"
                class="relative inline-flex items-center h-7 rounded-full w-14"
            >
              <span class="sr-only">Use Dark Mode</span>
              <span
                  :class="useDarkTheme ? 'translate-x-8' : 'translate-x-1'"
                  class="inline-block w-5 h-5 bg-white rounded-full transition duration-200 ease-in-out transform"
              />
            </Switch>
            <SwitchLabel passive>
              <MoonIcon class="w-6 h-6 text-blue-400" />
            </SwitchLabel>
          </div>
        </SwitchGroup>
      </div>
    </form>
  </Screen>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { debounce } from 'lodash'
import { UserError } from '@/utils'
import Screen from "@/components/Screen"
import PrimaryButton from "@/components/PrimaryButton"
import SecondaryButton from "@/components/SecondaryButton"
import PrettyLabel from '@/components/Label'
import PrettyInput from '@/components/Input'
import { CheckCircleIcon, SunIcon, MoonIcon } from '@heroicons/vue/outline'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    Screen,
    PrimaryButton,
    SecondaryButton,
    PrettyLabel,
    PrettyInput,
    CheckCircleIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
    SunIcon,
    MoonIcon,
    VueCropper
  },

  setup () {
    const store = useStore()
    const router = useRouter()

    const goBack = async function () {
      if (! username.value) {
        usernameInput.value.shake()
        usernameInput.value.focus()
        return
      }

      if (window.history.length) {
        await router.go(-1)
      }
      await router.replace({ name: 'lobby' })
    }

    const user = computed(() => store.state.user.user);

    const usernameInput = ref(null)
    const username = ref(user.value.username)
    const usernameSaved = ref(false)
    const usernameChanged = debounce(async () => {
      try {
        if (! username.value) {
          usernameInput.value.shake()
          return;
        }

        await store.dispatch('user/updateProfile', {
          username: username.value
        })

        usernameSaved.value = true
        setTimeout(() => usernameSaved.value = false, 1000)
      } catch (e) {
        usernameInput.value.shake()
        throw new UserError(e.message)
      }
    }, 500)

    const avatarFileInput = ref(null)
    const cringeAvatarFileInput = ref(null)

    let avatarType = null
    const avatarSource = ref(null)
    const avatarCropper = ref(null)
    const avatarUploading = ref(false)

    const avatarPicked = function (type, e) {
      avatarType = type

      const file = e.target.files[0]

      if (file.type.indexOf('image/') === -1) {
        throw new UserError('I need your face')
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        avatarCropper.value.replace(e.target.result)
        avatarSource.value = e.target.result
      }
      reader.readAsDataURL(file)
    }

    const avatarCrop = async function () {
      if (avatarCropper.value) {
        try {
          avatarUploading.value = true
          const payload = {}
          payload[avatarType] = avatarCropper.value.getCroppedCanvas().toDataURL()
          await store.dispatch('user/updateProfile', payload)
        } catch (e) {
          throw new UserError('Something went wrong 🙃')
        } finally {
          avatarType = null
          avatarSource.value = null
          avatarUploading.value = false
        }
      }
    }

    const useDarkTheme = ref(store.state.preferences.theme === 'dark')
    watch(useDarkTheme, useDarkTheme => {
      store.commit('preferences/setTheme', useDarkTheme ? 'dark' : 'light')
    })

    return {
      goBack,
      user,
      usernameInput,
      username,
      usernameSaved,
      usernameChanged,
      avatarFileInput,
      cringeAvatarFileInput,
      avatarSource,
      avatarCropper,
      avatarUploading,
      avatarPicked,
      avatarCrop,
      useDarkTheme
    }
  }
}
</script>

<template>
  <Screen>
    <template #title>Prepare Your Anus</template>
    <form @submit.prevent="submit">
      <div>
        <PrettyLabel for="username" class="block">Username</PrettyLabel>
        <PrettyInput type="text"
                     id="username"
                     ref="usernameInput"
                     v-model="username"
                     class="mt-1 block w-48" />
      </div>
      <div class="mt-4">
        <PrimaryButton type="submit" :disabled="submitting" :loading="submitting">Let's Go</PrimaryButton>
      </div>
    </form>
  </Screen>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Screen from "@/components/Screen"
import PrimaryButton from "@/components/PrimaryButton"
import PrettyLabel from '@/components/Label'
import PrettyInput from '@/components/Input'

export default {
  components: {
    Screen,
    PrimaryButton,
    PrettyLabel,
    PrettyInput
  },

  setup () {
    const store = useStore()
    const router = useRouter()

    const usernameInput = ref(null)
    const username = ref('')

    onMounted(() => usernameInput.value.focus())

    const submitting = ref(false)
    const submit = async function () {
      if (! username.value) {
        usernameInput.value.shake()
        usernameInput.value.focus()
        return
      }

      submitting.value = true
      try {
        await store.dispatch('user/register', {
          username: username.value,
          avatar: null
        })

        await router.replace({ name: 'lobby' })
      } catch (e) {
        usernameInput.value.shake()
        usernameInput.value.focus()
        throw e
      } finally {
        submitting.value = false
      }
    }

    return {
      usernameInput,
      username,
      submitting,
      submit,
    }
  }
}
</script>

<template>
  <TransitionGroup name="list" tag="div" class="my-4 divide-y dark:divide-gray-500">
    <PlayerItem v-for="player in players"
                :key="`player-${player.id}`"
                :player="player"
                :readinessStrategy="readinessStrategy"
                :show-score="showScore" />
  </TransitionGroup>
</template>

<script>
import PlayerItem from "@/components/PlayerItem"

export default {
  components: {
    PlayerItem
  },
  props: {
    players: {
      type: Array,
      required: true
    },
    showScore: {
      type: Boolean,
      default: false
    },
    readinessStrategy: Function
  }
}
</script>

import { createStore } from 'vuex'
import game from './modules/game'
import user from './modules/user'
import preferences from './modules/preferences'
import questions from './modules/questions'

export default createStore({
  state: {
    bootstrapped: false,
  },

  getters: {

  },

  mutations: {
    bootstrapped (state) {
      state.bootstrapped = true
    },
  },

  actions: {
    async bootstrap ({ commit, dispatch }) {
      await dispatch('user/fetchUser')
      await dispatch('game/fetchCurrentGame')
      commit('bootstrapped')
    }
  },

  modules: {
    game,
    user,
    preferences,
    questions
  }
})

<template>
  <form @submit.prevent="voteForAnswer">
    <div class="my-4">
      <RadioGroup v-model="selected">
        <RadioGroupLabel class="sr-only">Top Answer</RadioGroupLabel>
        <div class="divide-y">
          <RadioGroupOption
              as="template"
              v-for="answer in question.answers"
              :key="answer.id"
              :value="answer.id"
              v-slot="{ active, checked }"
          >
            <div
                :class="[
                active ? '' : '',
                checked ? '' : '',
                animationStarted ? 'started' : '',
              ]"
                class="answers-stagger relative flex py-2 cursor-pointer focus:outline-none"
            >
              <div class="flex items-center justify-between w-full px-2">
                <RadioGroupLabel
                    as="div"
                    :class="checked ? '' : ''"
                    class="font-medium my-2 dark:text-white"
                >
                  {{ answer.answer }}
                </RadioGroupLabel>
                <div v-show="checked" class="flex-shrink-0 text-green-500">
                  <BadgeCheckIcon class="w-6 h-6" />
                </div>
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>

    <div v-if="selected" class="text-center">
      <PrimaryButton type="submit" :disabled="voting" :voting="voting">
        I choose you
      </PrimaryButton>
    </div>
  </form>
</template>

<script>
import { ref, onMounted, computed } from "vue"
import { useStore } from "vuex"
import PrimaryButton from "@/components/PrimaryButton"
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue"
import { BadgeCheckIcon } from "@heroicons/vue/outline"

export default {
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    BadgeCheckIcon,
    PrimaryButton
  },

  setup () {
    const store = useStore()

    const question = computed(() => store.state.game.currentQuestion)

    const selected = ref(null)
    const animationStarted = ref(false)

    const voting = ref(false)
    const voteForAnswer = async function () {
      if (!selected.value) {
        return
      }

      voting.value = true
      try {
        await store.dispatch('game/voteForAnswer', {
          answerId: selected.value
        })
      } finally {
        voting.value = false
      }
    }

    onMounted(() => {
      setTimeout(function () {
        animationStarted.value = true
      }, 1000)
    })

    return {
      question,
      selected,
      voting,
      voteForAnswer,
      animationStarted
    }
  }
}
</script>

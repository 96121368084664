<template>
  <div class="flex items-center justify-between py-3">
    <div class="flex items-center space-x-4">
      <Avatar :player="player" :class="[isReady ? 'ring-green-500' : '', player.isOffline ? 'ring-red-500' : '']" />
      <div class="font-medium dark:text-white">{{ player.username }}</div>
      <div v-if="isReady" class="flex items-center space-x-2 text-green-500 text-sm">
        <CheckCircleIcon class="w-5 h-5" />
        <div>Ready!</div>
      </div>
      <div v-if="player.isOffline" class="flex items-center space-x-2 text-red-500 text-sm animate-pulse">
        <StatusOfflineIcon class="w-5 h-5" />
        <div>Offline</div>
      </div>
      <slot />
    </div>
    <div class="flex items-center space-x-2">
      <Menu v-if="isHost" as="div" class="relative inline-block text-left">
        <div>
          <MenuButton class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <MenuIcon class="w-4 h-4 dark:text-white" />
          </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="origin-top-right absolute z-10 right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1 divide-y divide-gray-100 bg-red-500">
              <MenuItem @click.prevent="kickPlayer(player)">
                <button class="text-white block w-full px-4 py-2 text-sm">Kick!</button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
      <div v-if="showScore" class="text-xl dark:text-white">
        {{ player.score }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import Avatar from "./Avatar"
import { CheckCircleIcon, StatusOfflineIcon, MenuIcon } from '@heroicons/vue/outline'
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue"

export default {
  components: {
    Avatar,
    CheckCircleIcon,
    StatusOfflineIcon,
    MenuIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
  },
  props: {
    player: {
      type: Object,
      required: true
    },
    showScore: {
      type: Boolean,
      default: false
    },
    readinessStrategy: Function
  },

  setup (props) {
    const store = useStore()

    const isHost = computed(() => store.getters['game/isHost'])

    const kickPlayer = async function (player) {
      if (!isHost.value) return

      await store.dispatch('game/kickPlayer', {
        userId: player.id
      })
    }

    return {
      isHost,
      kickPlayer,
      isReady: computed(() => typeof props.readinessStrategy === 'function' && props.readinessStrategy(props.player))
    }
  }
}
</script>

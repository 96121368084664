const useDarkTheme = localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)

const themes = ['dark', 'light']

export default {
  state: {
    theme: useDarkTheme ? 'dark' : 'light'
  },
  mutations: {
    setTheme (state, theme) {
      if (! themes.includes(theme)) {
        throw new Error('Invalid theme, must be ' + themes.join(' or '))
      }

      state.theme = theme
      localStorage.theme = theme
    }
  },
  namespaced: true,
}

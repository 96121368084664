<template>
  <div class="pb-4">
    <div class="flex justify-between items-center mb-4">
      <div class="flex items-center">
        <button v-if="showReturnButton" @click="$emit('return')" class="text-purple-700 dark:text-white mr-2">
          <ChevronLeftIcon class="h-8 w-8" />
        </button>
        <div>
          <h1 class="text-2xl dark:text-white"><slot name="title" /></h1>
          <h2 class="text-sm text-gray-300 dark:text-white "><slot name="subtitle" /></h2>
        </div>
      </div>
      <slot name="nav" />
    </div>
    <slot />
  </div>
</template>

<script>
import { ChevronLeftIcon } from '@heroicons/vue/outline'

export default {
  components: {
    ChevronLeftIcon
  },

  props: {
    showReturnButton: {
      type: Boolean,
      default: false
    }
  }
}
</script>

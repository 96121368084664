<template>
  <input
      class="focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-md"
      :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input">
</template>

<script>
export default {
  props: ['modelValue'],

  emits: ['update:modelValue'],

  methods: {
    focus () {
      this.$refs.input.focus()
    },

    shake () {
      this.$refs.input.classList.add('animate-shake')
      setTimeout(() => {
        if (this.$refs.input) {
          this.$refs.input.classList.remove('animate-shake')
        }
      }, 800)
    }
  }
}
</script>


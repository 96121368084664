const createStorageUrl = function (url) {
  return process.env.VUE_APP_STORAGE_URL + '/' + url
}

const createAvatarUrl = function (avatar) {
  return avatar ?
    createStorageUrl(avatar) :
    'https://picsum.photos/200/200?random=' + Math.floor(Math.random() * 100000)
}

const createJoinGameUrl = function (code) {
  return window.location.host + '?join=' + code
}

export {
  createAvatarUrl,
  createJoinGameUrl
}

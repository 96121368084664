import { createAvatarUrl } from '@/utils'

export default class User {
  constructor (source = {}) {
    Object.assign(this, source)
  }

  get avatarUrl () {
    return createAvatarUrl(this.avatarName)
  }

  get cringeAvatarUrl () {
    return createAvatarUrl(this.psychAvatarName)
  }
}
